import React from 'react'

import { IS_LOCAL, STAGE } from '@src/constants'

const Home = React.lazy(() => import('../pages/home-activity'))
const ModalsHome = React.lazy(() => import('src/pages/home-activity/modals'))
const UserLocationEdit = React.lazy(
  () => import('@src/pages/user-location-search/PageUserLocationEdit')
)
const UserLocationSearch = React.lazy(
  () => import('@src/pages/user-location-search/PageUserLocationSearch')
)
const UserLocationTermsBottomSheet = React.lazy(
  () =>
    import(
      '@src/pages/user-location-terms-bottom-sheet/PageUserLocationTermsBottomSheet'
    )
)
const UserLocationTermsDetail = React.lazy(
  () =>
    import('@src/pages/user-location-terms-detail/PageUserLocationTermsDetail')
)
const Test = React.lazy(() => import('../pages/_development/PageTest'))

const USE_INTERNAL = IS_LOCAL || STAGE === 'development' || STAGE === 'alpha'

const internalRoutes = [
  {
    name: 'test',
    desc: '테스트 페이지',
    key: 'Test',
    path: '/',
    activity: USE_INTERNAL ? Test : Home,
  },
] as const

/**
 * 동네지도 라우트
 * @scheme
 * - name: 라우트 이름 (screenName)
 * - desc: 라우트 설명
 * - key: 라우트 key로 쓸 값
 * - path: 라우트 경로
 * - activity: 라우트 컴포넌트
 */
const routes = [
  ...internalRoutes,
  {
    name: 'home',
    desc: '동네지도 홈',
    key: 'Home',
    path: '/home',
    activity: Home,
  },
  {
    name: 'modalsHome',
    desc: '동네지도 모달',
    key: 'ModalsHome',
    path: '/home/modals/:modalType',
    activity: ModalsHome,
  },
  {
    name: 'userLocationEdit',
    desc: '동네지도 장소 탐색 지도 뷰',
    key: 'UserLocationInMap',
    path: '/user-location',
    activity: UserLocationEdit,
  },
  {
    name: 'userLocationSearch',
    desc: '동네지도 장소 탐색 검색 뷰',
    key: 'UserLocationSearch',
    path: '/user-location/search',
    activity: UserLocationSearch,
  },
  {
    name: 'userLocationTermsBottomSheet',
    desc: '동네지도 대표위치 등록 이용약관',
    key: 'UserLocationTermsBottomSheet',
    path: '/user-location/terms',
    activity: UserLocationTermsBottomSheet,
  },
  {
    name: 'userLocationTermsDetail',
    desc: '동네지도 대표위치 등록 이용약관 상세',
    key: 'UserLocationTermsDetail',
    path: '/user-location/terms/detail',
    activity: UserLocationTermsDetail,
  },
] as const

// @ts-ignore
export const routeNames = routes.map((route) => route.name) as const
export type RoutesNames = (typeof routeNames)[number]

export default routes
