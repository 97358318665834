/* tslint:disable */
/* eslint-disable */
/**
 * Local Map - Local Map Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.3
 * Contact: local-map@daangn.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../../../runtime';
import type { TypeLatLng } from './TypeLatLng';
import {
    TypeLatLngFromJSON,
    TypeLatLngFromJSONTyped,
    TypeLatLngToJSON,
} from './TypeLatLng';

/**
 * 
 * @export
 * @interface V1CommunityCommentCommentary
 */
export interface V1CommunityCommentCommentary {
    /**
     * 
     * @type {string}
     * @memberof V1CommunityCommentCommentary
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof V1CommunityCommentCommentary
     */
    content: string;
    /**
     * 
     * @type {string}
     * @memberof V1CommunityCommentCommentary
     */
    taggedLocalProfileName: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof V1CommunityCommentCommentary
     */
    imagesUrls: Array<string>;
    /**
     * 
     * @type {TypeLatLng}
     * @memberof V1CommunityCommentCommentary
     */
    latlng: TypeLatLng;
}

/**
 * Check if a given object implements the V1CommunityCommentCommentary interface.
 */
export function instanceOfV1CommunityCommentCommentary(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "content" in value;
    isInstance = isInstance && "taggedLocalProfileName" in value;
    isInstance = isInstance && "imagesUrls" in value;
    isInstance = isInstance && "latlng" in value;

    return isInstance;
}

export function V1CommunityCommentCommentaryFromJSON(json: any): V1CommunityCommentCommentary {
    return V1CommunityCommentCommentaryFromJSONTyped(json, false);
}

export function V1CommunityCommentCommentaryFromJSONTyped(json: any, ignoreDiscriminator: boolean): V1CommunityCommentCommentary {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'content': json['content'],
        'taggedLocalProfileName': json['tagged_local_profile_name'],
        'imagesUrls': json['images_urls'],
        'latlng': TypeLatLngFromJSON(json['latlng']),
    };
}

export function V1CommunityCommentCommentaryToJSON(value?: V1CommunityCommentCommentary | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'content': value.content,
        'tagged_local_profile_name': value.taggedLocalProfileName,
        'images_urls': value.imagesUrls,
        'latlng': TypeLatLngToJSON(value.latlng),
    };
}

