import {
  createContext,
  type ReactNode,
  useContext,
  useState,
  useCallback,
  useEffect,
  useMemo,
} from 'react'

import { clientEnvironment, getGeolocation } from '@src/bridge/utils'
import { type Position } from '@src/components/karrot-map/types'
import { makeConcurrentResource } from '@src/ts-utils/concurrentResource'

import { IS_E2E, e2eGeoLocation } from '../searchPramaInsertion'

export type GeolocationPosition = Position

const DEFAULT_GEOLOCATION_RESPONSE = {
  currentPosition: null,
  granted: false,
}

const fetchGeolocation = async () => {
  const geolocation = IS_E2E
    ? e2eGeoLocation()
    : await getGeolocation({
        timeout: clientEnvironment.isWeb ? undefined : 2_500, // 최대 2.5초간 사용자가 위치 정보 제공을 동의하기를 기다림
        systemSettingPopupEnabled: true,
      })

  if (
    !geolocation?.currentPosition?.position || // iOS에서 빈 object로 옴
    !geolocation.currentPosition.position.latitude || // 안드로이드에서 0으로 옴
    !geolocation.currentPosition.position.longitude
  ) {
    return DEFAULT_GEOLOCATION_RESPONSE
  }

  const latitude = geolocation.currentPosition.position.latitude
  const longitude = geolocation.currentPosition.position.longitude

  return {
    currentPosition: {
      latitude: latitude,
      longitude: longitude,
    },
    granted: !(!latitude && !longitude), // 사용자의 선택이 아닌 위치 정보를 가져오지 못한 경우, granted를 true로 설정
  }
}

function makeGeolocationResource() {
  return makeConcurrentResource(fetchGeolocation())
}

export type BridgeGeolocation = Awaited<ReturnType<typeof fetchGeolocation>>

const GeolocationContext = createContext<BridgeGeolocation | null>(null)

const ReloadGeolocationContext = createContext<
  (() => Promise<BridgeGeolocation>) | null
>(null)

interface GeolocationResourceProviderProps {
  children: ReactNode
  onGeologationLoaded?: (geolocation: BridgeGeolocation) => void
}

const geoLocationResource = makeGeolocationResource()

export const GeolocationProvider = ({
  children,
  onGeologationLoaded,
}: GeolocationResourceProviderProps) => {
  const geolocationResource = geoLocationResource.read()

  const initialGeolocation = useMemo(
    () => ({
      currentPosition: geolocationResource?.currentPosition ?? null,
      granted: geolocationResource?.granted ?? false,
    }),
    [geolocationResource]
  )

  const [geolocation, setGeolocation] = useState(initialGeolocation)

  useEffect(() => {
    if (!initialGeolocation) {
      return
    }

    onGeologationLoaded?.(initialGeolocation)
  }, [initialGeolocation, onGeologationLoaded])

  const reloadGeolocation = useCallback(async () => {
    const newGeolocation = await fetchGeolocation()
    setGeolocation(newGeolocation)
    return newGeolocation
  }, [])

  return (
    <GeolocationContext.Provider value={geolocation}>
      <ReloadGeolocationContext.Provider value={reloadGeolocation}>
        {children}
      </ReloadGeolocationContext.Provider>
    </GeolocationContext.Provider>
  )
}

export function useGeolocation() {
  const geolocation = useContext(GeolocationContext)

  if (!geolocation) {
    return DEFAULT_GEOLOCATION_RESPONSE
  }

  return geolocation
}

export function useReloadGeolocation() {
  const reloadGeolocation = useContext(ReloadGeolocationContext)

  if (!reloadGeolocation) {
    throw new Error(
      'useReloadGeolocation must be used within GeolocationProvider'
    )
  }

  return reloadGeolocation
}
