/* tslint:disable */
/* eslint-disable */
/**
 * Local Map - Local Map Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.3
 * Contact: local-map@daangn.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * - SEARCH_QUERY_PROPERTY_TYPE_UNSPECIFIED: 지정되지 않음
 *  - SEARCH_QUERY_PROPERTY_TYPE_UNKNOWN: 알 수 없음
 * 
 * 전문가
 *  - SEARCH_QUERY_PROPERTY_TYPE_EXPERT: NOTE(neth): deprecated. use instead SEARCH_QUERY_PROPERTY_TYPE_EXPERT_LOCATION_INDEPENDENT.
 *  - SEARCH_QUERY_PROPERTY_TYPE_FISH_SHAPED_BREAD: 붕어빵
 * @export
 */
export const V1SearchQueryPropertyType = {
    Unspecified: 'SEARCH_QUERY_PROPERTY_TYPE_UNSPECIFIED',
    Unknown: 'SEARCH_QUERY_PROPERTY_TYPE_UNKNOWN',
    Expert: 'SEARCH_QUERY_PROPERTY_TYPE_EXPERT',
    FishShapedBread: 'SEARCH_QUERY_PROPERTY_TYPE_FISH_SHAPED_BREAD'
} as const;
export type V1SearchQueryPropertyType = typeof V1SearchQueryPropertyType[keyof typeof V1SearchQueryPropertyType];


export function V1SearchQueryPropertyTypeFromJSON(json: any): V1SearchQueryPropertyType {
    return V1SearchQueryPropertyTypeFromJSONTyped(json, false);
}

export function V1SearchQueryPropertyTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): V1SearchQueryPropertyType {
    return json as V1SearchQueryPropertyType;
}

export function V1SearchQueryPropertyTypeToJSON(value?: V1SearchQueryPropertyType | null): any {
    return value as any;
}

