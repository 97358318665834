import { Skeleton } from '@src/components/skeleton'

import * as css from './SectionRecommendPostItem.css'

const SectionRecommendPostItemSkeleton = () => {
  return (
    <article className={css.container}>
      <div className={css.flexContainer}>
        <div className={css.main}>
          <div className={css.bio}>
            <Skeleton width="30%" height="1rem" />
          </div>
          <div className={css.postTitle}>
            <Skeleton width="80%" height="1.375rem" />
          </div>
          <div
            className={css.captions}
            style={{ width: '100%', marginTop: '1.375rem' }}
          >
            <Skeleton width="20%" height="1rem" />
          </div>
        </div>
        <div className={css.imageWrapper}>
          <Skeleton width="100%" height="100%" />
        </div>
      </div>
    </article>
  )
}

export default SectionRecommendPostItemSkeleton
