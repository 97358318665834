/* tslint:disable */
/* eslint-disable */
/**
 * Local Map - Local Map Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.3
 * Contact: local-map@daangn.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../../../runtime';
import type { V1SearchCategoryKeywordsSuggestion } from './V1SearchCategoryKeywordsSuggestion';
import {
    V1SearchCategoryKeywordsSuggestionFromJSON,
    V1SearchCategoryKeywordsSuggestionFromJSONTyped,
    V1SearchCategoryKeywordsSuggestionToJSON,
} from './V1SearchCategoryKeywordsSuggestion';
import type { V1SearchQueryPropertyType } from './V1SearchQueryPropertyType';
import {
    V1SearchQueryPropertyTypeFromJSON,
    V1SearchQueryPropertyTypeFromJSONTyped,
    V1SearchQueryPropertyTypeToJSON,
} from './V1SearchQueryPropertyType';
import type { V1SearchQueryType } from './V1SearchQueryType';
import {
    V1SearchQueryTypeFromJSON,
    V1SearchQueryTypeFromJSONTyped,
    V1SearchQueryTypeToJSON,
} from './V1SearchQueryType';
import type { V1SearchResultItem } from './V1SearchResultItem';
import {
    V1SearchResultItemFromJSON,
    V1SearchResultItemFromJSONTyped,
    V1SearchResultItemToJSON,
} from './V1SearchResultItem';

/**
 * 
 * @export
 * @interface V1SearchLocalProfilesResponse
 */
export interface V1SearchLocalProfilesResponse {
    /**
     * 
     * @type {string}
     * @memberof V1SearchLocalProfilesResponse
     */
    nextPageToken?: string;
    /**
     * 
     * @type {Array<V1SearchResultItem>}
     * @memberof V1SearchLocalProfilesResponse
     */
    searchResultItems: Array<V1SearchResultItem>;
    /**
     * 
     * @type {V1SearchCategoryKeywordsSuggestion}
     * @memberof V1SearchLocalProfilesResponse
     */
    searchCategoryKeywordsSuggestion?: V1SearchCategoryKeywordsSuggestion;
    /**
     * 
     * @type {V1SearchQueryPropertyType}
     * @memberof V1SearchLocalProfilesResponse
     */
    queryPropertyType: V1SearchQueryPropertyType;
    /**
     * 
     * @type {Array<V1SearchQueryType>}
     * @memberof V1SearchLocalProfilesResponse
     */
    searchQueryTypes: Array<V1SearchQueryType>;
}

/**
 * Check if a given object implements the V1SearchLocalProfilesResponse interface.
 */
export function instanceOfV1SearchLocalProfilesResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "searchResultItems" in value;
    isInstance = isInstance && "queryPropertyType" in value;
    isInstance = isInstance && "searchQueryTypes" in value;

    return isInstance;
}

export function V1SearchLocalProfilesResponseFromJSON(json: any): V1SearchLocalProfilesResponse {
    return V1SearchLocalProfilesResponseFromJSONTyped(json, false);
}

export function V1SearchLocalProfilesResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): V1SearchLocalProfilesResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'nextPageToken': !exists(json, 'next_page_token') ? undefined : json['next_page_token'],
        'searchResultItems': ((json['search_result_items'] as Array<any>).map(V1SearchResultItemFromJSON)),
        'searchCategoryKeywordsSuggestion': !exists(json, 'search_category_keywords_suggestion') ? undefined : V1SearchCategoryKeywordsSuggestionFromJSON(json['search_category_keywords_suggestion']),
        'queryPropertyType': V1SearchQueryPropertyTypeFromJSON(json['query_property_type']),
        'searchQueryTypes': ((json['search_query_types'] as Array<any>).map(V1SearchQueryTypeFromJSON)),
    };
}

export function V1SearchLocalProfilesResponseToJSON(value?: V1SearchLocalProfilesResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'next_page_token': value.nextPageToken,
        'search_result_items': ((value.searchResultItems as Array<any>).map(V1SearchResultItemToJSON)),
        'search_category_keywords_suggestion': V1SearchCategoryKeywordsSuggestionToJSON(value.searchCategoryKeywordsSuggestion),
        'query_property_type': V1SearchQueryPropertyTypeToJSON(value.queryPropertyType),
        'search_query_types': ((value.searchQueryTypes as Array<any>).map(V1SearchQueryTypeToJSON)),
    };
}

