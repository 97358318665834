/* tslint:disable */
/* eslint-disable */
/**
 * Local Map - Local Map Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.3
 * Contact: local-map@daangn.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const V1SearchQueryType = {
    Unspecified: 'SEARCH_QUERY_TYPE_UNSPECIFIED',
    FishShapedBread: 'SEARCH_QUERY_TYPE_FISH_SHAPED_BREAD',
    ExpertSmallScaleDelivery: 'SEARCH_QUERY_TYPE_EXPERT_SMALL_SCALE_DELIVERY',
    ExpertCleaning: 'SEARCH_QUERY_TYPE_EXPERT_CLEANING',
    ExpertRepair: 'SEARCH_QUERY_TYPE_EXPERT_REPAIR',
    ExpertConstruction: 'SEARCH_QUERY_TYPE_EXPERT_CONSTRUCTION',
    ExpertBeauty: 'SEARCH_QUERY_TYPE_EXPERT_BEAUTY',
    ExpertEyelash: 'SEARCH_QUERY_TYPE_EXPERT_EYELASH',
    ExpertNail: 'SEARCH_QUERY_TYPE_EXPERT_NAIL',
    ExpertHair: 'SEARCH_QUERY_TYPE_EXPERT_HAIR'
} as const;
export type V1SearchQueryType = typeof V1SearchQueryType[keyof typeof V1SearchQueryType];


export function V1SearchQueryTypeFromJSON(json: any): V1SearchQueryType {
    return V1SearchQueryTypeFromJSONTyped(json, false);
}

export function V1SearchQueryTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): V1SearchQueryType {
    return json as V1SearchQueryType;
}

export function V1SearchQueryTypeToJSON(value?: V1SearchQueryType | null): any {
    return value as any;
}

